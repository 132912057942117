import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide53/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide53/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide53/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide53/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide53/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    10 ejemplos de recordatorios de pago por SMS y WhatsApp 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Hoy en día, la comunicación rápida y eficiente es clave para mantener la fluidez en las operaciones empresariales, especialmente cuando se trata de cobranzas. Los mensajes de texto y WhatsApp se han convertido en herramientas esenciales para las empresas que buscan optimizar este proceso.
     </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
Estos servicios permiten enviar recordatorios de pago de manera directa y personalizada, facilitando así una gestión de cobros más ágil y menos intrusiva. Estadísticas recientes muestran que WhatsApp tiene una tasa de apertura del 98%. Por otro lado, SMS cuenta con un 90% de open rate.  
<br /><br />
Dado este contexto, resulta fundamental para cualquier negocio implementar estrategias que incorporen el uso de SMS y WhatsApp para el envío de recordatorios de pago. Este método no solo mejora la tasa de respuesta de los clientes, sino que también contribuye a mantener una relación cordial y continua con ellos. 
 </p>
)

const data = {
  start: {
    support: "Guía | Cobranza ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Plantillas para recordatorios formales.",
      },
      {
        key: 2,
        text: "Plantillas para recordatorios personalizados.",
      },
      {
        key: 3,
        text: "Plantillas con opción de asistencia.",
      },
      {
        key: 4,
        text: "Plantillas de recordatorios urgentes.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
